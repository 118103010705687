import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/page/index.vue')
  },
	{
	  path: '/login',
	  name: 'login',
	  component: () => import('../views/login/index.vue')
	}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to,from) => {
  if(!localStorage.getItem('token') &&  to.name !== 'login') {
    return {name:'login'}
  }
})
export default router
